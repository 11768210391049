#span1{
    color: #781184;
}
#span2{
    color: #751184;
}
#span3{
    color: rgb(111, 17, 132);
}
#span4{
    color: rgb(103, 17, 132);
}
#spanT{
    color: rgb(94, 17, 132);
}

#span5{
    color: rgb(91, 17, 132);

}
#span6{
    color: rgb(80, 17, 132);
}
#span7{
    color: rgb(75, 17, 132);
}
#span8{
    color: rgb(70, 17, 132);
}

#span9{
    color: rgb(65, 17, 132);
}

#span10{
    color: rgb(60, 17, 132);
}
#span11{
    color: rgb(55, 17, 132);
}
#span12{
    color: rgb(50, 17, 132);
}
#span13{
    color: rgb(45, 17, 132);
}
#span14{
    color: rgb(40, 17, 132);
}
#span15{
    color: rgb(35, 17, 132);
}

#firstH1{
    margin-top: -80px;
}